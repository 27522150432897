import React from 'react'
// import Common Banner
import CommonBanner from '../component/Common/CommonBanner'
// import AboutArea
import AboutArea from '../component/About/AboutArea'
// import DiscountArea
import DiscountArea from '../component/About/DiscountArea'
// import AboutServices
import AboutServices from '../component/About/Services'
// import Consultation
import Consultation from '../component/About/Consultation'
// import Counter
import Counter from '../component/About/Counter'
// import CustomerReview
import CustomerReview from '../component/About/CustomerReview'

import { Helmet } from 'react-helmet';



const About = () => {
  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://www.safarontime.com/about" />
        <title>Safar On Time - About Us</title>
        <meta name="description" content="Learn more about Safar On Time - Your ultimate tool for finding the most reliable flights, avoiding flight delays and ensuring you reach your destination on time, every time!" />
      </Helmet>
      <CommonBanner heading="About us" pagination="About" />
      <AboutArea />
      {/* <DiscountArea/>
    <AboutServices/>
    <Consultation/>
    <Counter/>
    <CustomerReview/> */}
    </>
  )
}

export default About