import React from 'react'
// import commonBanner
import CommonBanner from '../component/Common/CommonBanner'
// import ContactArea
import ContactArea from '../component/Contact'
import { Helmet } from 'react-helmet';

const Contact = () => {
  return (
    <>
    <Helmet>
        <link rel="canonical" href="https://www.safarontime.com/contact" />
        <title>Safar On Time - Contact</title>
        <meta name="description" content="Get in touch with Safar On Time for inquiries, support, or feedback. Our dedicated team is here to assist you with finding the most reliable flights, avoiding flight delays and ensuring you reach your destination on time, every time!" />
      </Helmet>
     <CommonBanner heading="Contact" pagination="Contact"/>
     <ContactArea/>
    </>
  )
}

export default Contact